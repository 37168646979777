import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetDraftListParameters, GetDraftParameters, PostDraftCompletedParameters, PostDraftParameters, PutDraftParameters } from '../../api';
import { DatePicker, Input } from 'antd';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';



interface PageState {

}

export type DraftEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }


interface Props {
  onFinish?(props: PageProps, id: string): void

}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & DraftEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class DraftEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
    }

  }
  componentDidMount() {
  }


  _putDraft = async (params: PutDraftParameters) => {
    const res = await api.PutDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postDraft = async (params: PostDraftParameters) => {
    const res = await api.PostDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getDraftList = async (params: GetDraftListParameters) => {
    const res = await api.GetDraftList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _getDraft = async (params: GetDraftParameters) => {
    const res = await api.GetDraft(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onFinish = async (values: any): Promise<void> => {

    switch (this.props.type) {
      case 'edit': {

        const res = await this._putDraft({
          ...values,
          id: this.props.id,
        })

        this.props.onFinish && this.props.onFinish(this.props, this.props.id)
      }
        break
      case 'add': {
        const res = await this._postDraft({
          ...values,
        })

        this.props.onFinish && this.props.onFinish(this.props, 'xxxx')

      }
        break

    }
  }


  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getDraft({ id: this.props.id })
        const approvedAt = res.approvedAt ? moment(res.approvedAt) : undefined

        return {
          ...res,
          approvedAt: approvedAt,
        }

      }
      case 'add': {
        return {}
      }
    }
  };

  _formListData = (): FormItemData[] => {


    switch (this.props.type) {

      case 'edit': {

        return [
          {

            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input.TextArea
                rows={4}
              />
            )
          },
          {
            id: 'approvedAt',
            label: '審批時間',
            rules: [
              {
                required: false,
                message: `請輸入 審批時間`
              }
            ],
            componet: (
              <DatePicker picker='date' showTime />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {
        return [


          {

            id: 'name',
            label: '名稱',
            rules: [
              {
                required: true,
              }
            ],
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <Input.TextArea
                rows={4}
              />
            )
          },
          {
            id: 'approvedAt',
            label: '審批時間',
            rules: [
              {
                required: false,
                message: `請輸入 審批時間`
              }
            ],
            componet: (
              <DatePicker picker='date' showTime />
            )
          },
          {
            id: 'submit',
          },
        ]
      }

    }
  }

  render() {
    return (
      <div id="DraftEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}

        />
      </div>
    )
  }

}
export default connector(DraftEditAndAdd)
