import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { finalPriceData, GetDraftMachineRes, GetDraftProductRes, GetDraftRes, GetProductBrandRes, GetProductTypeRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import tool from "../tool"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import { Image, Tag } from 'antd'
import JJ_TableItemMoment from "../components/JJ_TableItemMoment"



export type DraftProductColumnData = GetDraftProductRes & {
  draft?: GetDraftRes
  finalPriceDataObj?: {
    [draftMachineId: string]: {
      draftMachine: GetDraftMachineRes
      finalPriceData: finalPriceData
    }
  }
}
type DraftProductColumnParamOnActionKey = 'edit' | 'delete'

export type DraftProductColumnParams = {
  user: ActionUserRes
  onAction?(key: DraftProductColumnParamOnActionKey, record: DraftProductColumnData): void
  finalPriceDataObj?: {
    [draftMachineId: string]: {
      draftMachine: GetDraftMachineRes
      finalPriceData: finalPriceData
    }
  }
  productBrands: GetProductBrandRes[]
  productTypes: GetProductTypeRes[]
}

export const getDraftProductColumns = (params?: DraftProductColumnParams): ColumnsType<DraftProductColumnData> => {


  const getFinalPriceDataList = () => {

    const finalPriceDataObj = params?.finalPriceDataObj

    if (finalPriceDataObj) {
      return Object.keys(finalPriceDataObj).map(draftMachineId => {
        const item = finalPriceDataObj[draftMachineId]
        return {
          title: item.draftMachine.name,
          dataIndex: draftMachineId,
          key: draftMachineId,
          width: 150,
          render: (value, record: DraftProductColumnData) => {
            if (record.finalPriceDataObj && record.finalPriceDataObj[draftMachineId]) {


              const showRed = record.finalPriceDataObj[draftMachineId].finalPriceData.finalPrice === 999


              return showRed ? '--' : tool.number.formatNumber(record.finalPriceDataObj[draftMachineId].finalPriceData.finalPrice, 1)

            }
          },

          onCell: (record) => {


            if (record.finalPriceDataObj && record.finalPriceDataObj[draftMachineId]) {

              const showRed = record.finalPriceDataObj[draftMachineId].finalPriceData.finalPrice === 999
              const backgroundColor = showRed ? 'rgba(230,135,135,0.05)' : undefined
              return {
                style: { backgroundColor }
              }

            }

            return {

            }

          },



        }
      })
    }
    return []

  }


  return [
    {
      key: 'action',
      width: 50,
      fixed: 'left',
      render: (value: any, record) => {
        const menusData: { key: DraftProductColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'edit', name: '編輯', disabled: !!(record.draft?.approveStatus !== 'BEGIN') },
          // { key: 'delete', name: '刪除', disabled: !!(record.draft?.approveStatus !== 'BEGIN') },

        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: item.disabled,
                key: item.key,
                name: item.name,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },

    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      fixed: 'left',
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '產品ID',
      dataIndex: ['productId',],
      key: 'productId',
      width: 80,
      fixed: 'left',
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },


    {
      title: '唯一碼',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      fixed: 'left',
    },

    {
      title: '名稱',
      dataIndex: ['name'],
      key: 'name',
      width: 200,
      fixed: 'left',
      render: (value, record) => tool.local.formatMessage({ user: params?.user, data: record.name }),
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>零售價</div>,
      dataIndex: ['retailPrice'],
      key: 'retailPrice',
      width: 100,
      fixed: 'left',
      render: (value, record) => tool.number.formatNumber(value, 1),

    },


    {
      title: '圖片',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 70,
      fixed: 'left',
      render: (value, record) => (
        <Image
          width={60}
          src={record.imageUrl || ''}
          preview={true}
        />
      )
    },

    {
      title: <div style={{ color: tool.color.primary_color }}>成本</div>,
      dataIndex: ['costPrice'],
      key: 'costPrice',
      width: 100,
      render: (value, record) => tool.number.formatNumber(value, 1),
    },



    {
      title: '產品品牌',
      dataIndex: 'kioskProductBrandId',
      key: 'kioskProductBrandId',
      width: 150,
      render: (value, record) => {
        const breand = params?.productBrands.find(breand => breand.kioskProductBrandId === record.kioskProductBrandId)
        return breand?.name
      }
    },

    {
      title: '產品類別',
      dataIndex: 'kioskProductTypeId',
      key: 'kioskProductTypeId',
      width: 150,
      render: (value, record) => {
        const productType = params?.productTypes.find(productType => productType.kioskProductTypeId === record.kioskProductTypeId)
        return tool.local.formatMessage({ user: params?.user, data: productType?.name })
      }
    },

    {
      title: '是否啟用',
      dataIndex: ['isEnabled'],
      key: 'isEnabled',
      width: 100,
      render: (value, record) => {
        return (
          record.isEnabled
            ? <Tag color="blue">啟用</Tag>
            : <Tag color="red">停用</Tag>
        )
      }
    },

    ...(getFinalPriceDataList()),

    {
      title: '創建時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },
  ]
}
